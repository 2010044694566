import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem("user") || "{}") || {
      id: "",
      username: "",
      sexo: "",
      fecha_nacimiento: "",
      rut: "",
      email: "",
      phone: "",
      lenguage: "es",
      calle: "",
      numero: "",
      aclaratoria: "",
      id_calle: "",
      direccion: "",
      comuna: "",
      id_comuna: ""
    },
    authenticated: localStorage.getItem("auth_token") ? true : false,
    token: {
      auth_token: localStorage.getItem("auth_token") || "",
      refresh_token: localStorage.getItem("refresh_token") || ""
    },
    loading: true,
    applicationRut: localStorage.getItem("applicationRut") || "",
    new: true,
    from_login: {
      name: "",
      parameters: ""
    },
    authModal: false
  },
  getters: {
    fullname: state => {
      return `${state.user.username}`;
    }
  },
  mutations: {
    SET_USER(state, data) {
      Object.assign(state.user, data);
      localStorage.setItem("user", JSON.stringify(data));
      if (!state.user.lenguage) {
        state.user.lenguage = "es";
      }
    },
    UNSET_USER(state) {
      state.user = {
        id: "",
        username: "",
        sexo: "",
        fecha_nacimiento: "",
        rut: "",
        email: "",
        phone: "",
        lenguage: "es",
        direccion: ""
      };
    },
    SET_TOKEN(state, data) {
      Object.assign(state.token, data);
      state.authenticated = true;
    },
    UNSET_TOKEN(state) {
      state.token = {
        auth_token: "",
        refresh_token: ""
      };
      state.authenticated = false;
    },
    SET_LENGUAGE(state, data) {
      state.user.lenguage = data;
    },
    SET_AUTHMODAL(state, data) {
      state.authModal = data;
    },
    LOADING(state, data) {
      state.loading = data;
    },
    SET_APPLICATION_RUT(state, data) {
      state.applicationRut = data;
      localStorage.setItem("applicationRut", data);
    },
    UNSET_APPLICATION_RUT(state, data) {
      state.applicationRut = "";
      localStorage.setItem("applicationRut", "");
    },
    SET_NEW(state, data) {
      state.new = data;
    },
    SET_FROM_LOGIN(state, data) {
      state.from_login = data;
    },
    LOGOUT(state, data) {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user");
      state.token = {
        auth_token: "",
        refresh_token: ""
      };
      state.user = {
        id: "",
        username: "",
        sexo: "",
        fecha_nacimiento: "",
        rut: "",
        email: "",
        phone: "",
        lenguage: "es",
        direccion: ""
      };
      state.authenticated = false;
    }
  },
  actions: {
    GET_USER({ commit, dispatch }, data) {
      const user = {
        id: data.id,
        username: data.username,
        fecha_nacimiento: data.fecha_nacimiento,
        sexo: data.sexo,
        rut: data.rut,
        email: data.email,
        phone: data.telefono,
        lenguage: data.idioma,
        direccion: data.direccion,
        calle: data.calle,
        numero: data.numero,
        aclaratoria: data.aclaratoria,
        id_calle: data.id_calle,
        comuna: data.comuna,
        id_comuna: data.id_comuna
      };
      commit("SET_USER", user);
    },
    LOGOUT({ commit, dispatch }) {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refresh_token");
      commit("UNSET_USER");
      const user = {
        id: "",
        username: "",
        sexo: "",
        fecha_nacimiento: "",
        rut: "",
        email: "",
        phone: "",
        lenguage: "es",
        direccion: ""
      };
      commit("SET_USER", user);
      commit("UNSET_TOKEN");
    }
  }
});
