import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/",
    component: () =>
      import(/* webpackChunkName: "baseLayout" */ "@/layouts/BaseLayout.vue"),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "/registro",
        name: "registro",
        component: () =>
          import(
            /* webpackChunkName: "registro" */ "@/views/auth/RegisterView.vue"
          ),
        meta: {
          notLogged: true,
          requiresAuth: false,
          title: "Registro"
        }
      },
      {
        path: "/",
        name: "Talleres",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Talleres/TalleresView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Talleres y Actividades"
        }
      },
      {
        path: "/mis-inscripciones",
        name: "MisTalleres",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Talleres/MisTalleresView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Mis Inscripciones"
        }
      },

      {
        path: "/taller/perfil/:id/:nombre",
        name: "PerfilTaller",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Talleres/PerfilTallerView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Perfil"
        }
      },
      {
        path: "/taller/:nombre/:id",
        name: "PerfilHorario",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Talleres/PerfilHorarioView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Perfil Horario"
        }
      },

      {
        path: "/preguntas-frecuentes",
        name: "Preguntas",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/Preguntas/PreguntasView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Preguntas Frecuentes"
        }
      },

      {
        path: "/contacto",
        name: "Contacto",
        component: () =>
          import(
            /* webpackChunkName: "contacto" */ "@/views/Contacto/ContactoView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Contacto"
        }
      },

      {
        path: "/editar",
        name: "editar",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/menu/editarPerfilView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Editar"
        }
      },
      {
        path: "/cambiar-contrasena",
        name: "configuracion",
        component: () =>
          import(
            /* webpackChunkName: "panel-admin" */ "@/views/menu/ConfiguracionView.vue"
          ),
        meta: {
          requiresAuth: true,
          title: "Configuración"
        }
      },
      {
        path: "/categorias/:nombre",
        name: "Categorias",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/Talleres/CategoriasView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Categorias"
        }
      },
      { path: "/:pathMatch(.*)", redirect: { path: "/" } }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const authenticated = Store.state.authenticated;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  const notLogged = to.matched.some(record => record.meta.notLogged);

  if (requiresAuth && !authenticated) {
    next("/");
  } else if (!requiresAuth && authenticated) {
    if (notLogged) {
      next("/");
    } else {
      if (to && from && from.name == "PerfilTaller" && to.name == "login") {
        Store.commit("SET_FROM_LOGIN", {
          name: from.name,
          parameters: from.params.id
        });
      } else if (to && from && from.name == "login") {
        if (
          Store.state.from_login.name == "PerfilTaller" &&
          Store.state.from_login.parameters != ""
        ) {
          next();
        } else {
          Store.commit("SET_FROM_LOGIN", { name: "", parameters: "" });
        }
      }
      next();
    }
  } else {
    if (to && from && from.name == "PerfilTaller" && to.name == "login") {
      Store.commit("SET_FROM_LOGIN", {
        name: from.name,
        parameters: from.params.id
      });
    } else if (to && from && from.name == "login") {
      if (
        Store.state.from_login.name == "PerfilTaller" &&
        Store.state.from_login.parameters != ""
      ) {
        next();
      } else {
        Store.commit("SET_FROM_LOGIN", { name: "", parameters: "" });
      }
    }
    next();
  }
});
const DEFAULT_TITLE = "Talleres";
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = (to as any).meta.title || DEFAULT_TITLE;
  });
});
export default router;
